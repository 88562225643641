<template>
  <Component :is="component" />
</template>

<script>
import LayoutPrimary from "@/mixins/LayoutPrimary";
import ErrorPageDesktop from "~/components/Page/ErrorPage/ErrorPageDesktop.vue";
import ErrorPageMobile from "~/components/Page/ErrorPage/ErrorPageMobile.vue";

export default {
  name: 'ErrorPage',
  mixins: [LayoutPrimary],
  head() {
    return {
      title: 'Page not found (404)'
    }
  },
  computed: {
    component() {
      this.$nuxt.error({ statusCode: 404 });
      return this.$device.isDesktop ? ErrorPageDesktop : ErrorPageMobile;
    },
  },

  watch: {
    $route() {
      window.scrollTo(0,0);
    },
  },
};
</script>
